import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import timeago from 'vue-timeago3'
import { nl } from 'date-fns/locale';
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const timeagoOptions = {
	locale: nl,
	autoUpdate: true,
}

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({el, App, props, plugin}) {
		const app = createApp({render: () => h(App, props)});

		Sentry.init({
			app,
			dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
			environment: import.meta.env.VITE_APP_ENV,
			integrations: [
				Sentry.replayIntegration(),
			],
			tracesSampleRate: 1.0,
			tracePropagationTargets: [/^https:\/\/meld\.pandwachters\.nl\//],
			replaysSessionSampleRate: 1.0,
			replaysOnErrorSampleRate: 1.0,
		});

		app
			.use(plugin)
			.use(ZiggyVue)
			.use(timeago, timeagoOptions)
			.mount(el);

		return app;
	},
	progress: {
		color: '#ec663a',
	},
});
